import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls';
import UiKit from 'uikit';

export default (item, uid, service, product) ->
  self = @
  AxiosRequest('/customers/favourites', {item_id: item, customer_id: uid}, 'post').then ({data}) ->
    self.ReloadFavourite()
    self.GetFavourite()
    if product
      UiKit.modal(document.getElementById('product-modal-' + item)).hide();
    if service
      UiKit.modal(document.getElementById('service-modal-' + item)).hide();
    Swal.fire({
      title: data.message
      icon: 'success'
      toast: true
      position: 'top-end'
      timer: 2000
    }).then ->
      console.info 'success'
