import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'
import UiKit from 'uikit'

export default (product = null, selected_shipping = null, selected_pickup = null, service = null, uid = 'joker', dev, security_key, show = false) ->
  self = this
  self.selected_pickup = selected_pickup
  self.selected_shipping = selected_shipping
  if product
    stores = {}
    stores = product.store
    option_store = stores.map((el) => ("<option value=\"#{el.store_id}\">#{el.store_name}</option>"))
    if selected_pickup
      request = Swal.fire {
        title: "Seleccionar tienda"
        confirmButtonText: 'Continuar'
        cancelButtonText: 'Cancelar'
        width: '60%'
        showCancelButton: true
        allowOutsideClick: false
        html: "<label for='store'>Tienda</label>
              <select id='store' class='swal2-input' name='store'><option value=''>Seleccione una tienda</option>"+option_store+"</select>"
        preConfirm: ->
          store = document.getElementById('store').value
          if store == ''
             Swal.showValidationMessage('Selecciona una tienda.')
      }
      request.then (res) =>
        if res.value
          self.store_select = document.getElementById('store').value
        else
          self.selected_pickup = false
    else if selected_shipping
      AxiosRequest("/customers/shopping_carts/addresses.json", {idcustomer: uid}).then ({data}) ->
        if uid == 'joker'
          Swal.fire({
            icon: 'info'
            text: 'Para añadir este producto a domicilio, debes iniciar sesión',
            confirmButtonText: 'Continuar'
            cancelButtonText: 'Cancelar'
            showCancelButton: true
            allowOutsideClick: false
          }).then (res) ->
            if res.value
              self.selected_shipping = res.value
              await AxiosRequest("/return_to_item", {original_url: window.location.href}, "post")
              window.location.replace data.url
            else
              self.selected_shipping = false
        else
          if data.address
            address = data.address.map((e) => "<option value='#{e.id}'>#{e.full}</option>")
            Swal.fire(
              {
                confirmButtonText: 'Continuar'
                cancelButtonText: 'Cancelar'
                allowOutsideClick: false
                showCancelButton: true
                width: '60%'
                html:
                  "<label for='uid_address' class='uk-text-left'> Selecciona una dirección </label>
                  <select name='uid_address' id='uid_address' class='uk-input'>
                    <option value='0'>Selecciona una dirección de envio</option>"+address+"
                  </select>"
                preConfirm: ->
                  udi_address = document.getElementById('uid_address').value
                  if udi_address <= 0
                    Swal.showValidationMessage('Selecciona una dirección')
              }
            ).then (res) ->
              if res.value
                s_address = document.getElementById('uid_address').value
                if JSON.parse dev
                  router_api = 'http://dev.fenixrepuestos.com.co:3000'
                else
                  router_api = 'https://api.fenixrepuestos.com.co'

                authenticity_token = $('[name="csrf-token"]')[0].content

                final_data = {
                  address_id: s_address,
                  product_id: product.id
                  authenticity_token,
                  security_key
                }

                request = $.ajax(
                  {
                    url: "#{router_api}/api/v1/check_shipping",
                    data: JSON.stringify(final_data),
                    method: 'post',
                    processData: false
                    contentType: "application/json"
                  }
                )

                request.done (data) ->
                  self.selected_address = s_address
                  self.selected_shipping = res.value
                request.fail (data) ->
                  Swal.fire({
                    icon: 'error'
                    text: request.responseJSON.message
                    allowOutsideClick: false
                    showConfirmButton: false,
                    timer: 5000
                  }).then ->
                    self.selected_shipping = false
                    UiKit.modal("#product-modal-#{product.id}").hide() unless show
              else
                self.selected_shipping = false
          else
            states = data.states.map((e) => "<option value='#{e.idstate}'>#{e.name}</option>")
            Swal.fire({
              icon: 'error',
              title: data.message,
              confirmButtonText: 'Agregar'
              cancelButtonText: 'Cancelar'
              showCancelButton: true
              allowOutsideClick: false
              html:
                "
                <div class='uk-text-left'>
                  <label for='address' class='uk-form-label uk-text-bolder'>Dirección</label>
                  <input type='text' name='address' id='address' class='uk-input swal2-input uk-margin-auto-vertical' placeholder=''>
                  <label for='neighborhood' class='uk-form-label uk-text-bolder'>Barrio</label>
                  <input type='text' name='neighborhood' id='neighborhood' class='uk-input swal2-input uk-margin-auto-vertical' placeholder=''>
                  <div data-controller='master-detail' data-master-detail-victim='#city_id' data-master-detail-url='/states'>
                    <label for='state_id' class='uk-form-label uk-text-bolder'>Departamento</label>
                    <select id='state_id' class='swal2-input uk-input uk-select uk-margin-auto-vertical' data-target='master-detail.master' data-action='master-detail#setVictims'>
                      <option value=''>Seleccione una opción</option>
                      "+states+"
                    </select>
                  </div>
                  <label for='city_id' class='uk-form-label uk-text-bolder'>Ciudad</label>
                  <select id='city_id' class='swal2-input uk-input uk-select uk-margin-auto-vertical'>
                    <option value=''>Seleccione una opción</option>
                  </select>
                  <label for='name_address' class='uk-form-label uk-text-bolder'>Nombre para identificar esta dirección</label>
                  <input type='text' name='name_address' id='name_address' class='uk-input swal2-input uk-margin-auto-vertical' placeholder='Ej. Mi Casa..'>
                </div>
                "
              footer: "<a href='#{data.url}' class='uk-button blue-button'>Agregar otra dirección</a>"
              preConfirm: ->
                name = document.getElementById('name_address').value
                address = document.getElementById('address').value
                neighborhood = document.getElementById('neighborhood').value
                state_id = document.getElementById('state_id').value
                city_id = document.getElementById('city_id').value
                if name == '' || address == '' || neighborhood == '' || state_id == '' || city_id == ''
                  Swal.showValidationMessage('Dedes llenar los campos')
                else if name.length > 45
                  Swal.showValidationMessage('Nombre para identificar esta dirección es demasiado largo')
                else if address.length > 90
                  Swal.showValidationMessage('Dirección es demasiada larga')
                else if neighborhood.length > 45
                  Swal.showValidationMessage('Barrio es demasiado largo')
            }).then (res) ->
              if res.value
                self.selected_shipping = res.value
                name = document.getElementById('name_address').value
                address = document.getElementById('address').value
                neighborhood = document.getElementById('neighborhood').value
                state_id = document.getElementById('state_id').value
                city_id = document.getElementById('city_id').value
                address = {
                  name: name,
                  address: address,
                  neighborhood: neighborhood,
                  state_id: state_id,
                  city_id: city_id,
                  customer_id: uid
                }
                AxiosRequest('/customers/shopping_carts/add_address', {address: address}, 'post').then ({data}) ->
                  self.selected_address = data.idaddress
              else
                self.selected_shipping = res.value
  else if service
    store = service.stores.find((e) => e.store_id == self.store_select)
    if selected_shipping
      value = new Intl.NumberFormat({style: 'decimal', maximu4mSignificantDigits: 3 }).format(service.shipping.price)
      if service.shipping.free
        message = "El valor del Domicilio es Gratis"
      else
        message = "El valor del Domicilio es $ #{value}"
      AxiosRequest("/customers/shopping_carts/addresses.json", {idcustomer: uid}).then ({data}) ->
        if uid == 'joker'
          Swal.fire({
            icon: 'info'
            text: 'Para reservar este servicio a domicilio, debes iniciar sesión',
            confirmButtonText: 'Continuar'
            cancelButtonText: 'Cancelar'
            allowOutsideClick: false
          }).then (res) ->
            if res.value
              self.selected_shipping = res.value
              await AxiosRequest("/return_to_item", {original_url: window.location.href}, "post")
              window.location.replace data.url
            else
              self.selected_shipping = res.value
        else
          if data.address
            address = data.address.map((e) => "<option value='#{e.id}'>#{e.full}</option>")
            Swal.fire({
              title: ''
              confirmButtonText: 'Continuar'
              cancelButtonText: 'Cancelar'
              allowOutsideClick: false
              showCancelButton: true
              html:
                "<p>"+message+"</p>
                <label for='uid_address' class='uk-text-left'> Selecciona una dirección </label>
                <select name='uid_address' id='uid_address' class='uk-select swal2-input'>
                <option value='0'>Selecciona una dirección de envio</option>
                "+address+"
                </select>"
              preConfirm: ->
                udi_address = document.getElementById('uid_address').value
                if udi_address <= 0
                  Swal.showValidationMessage('Selecciona una dirección')
            }).then (res) ->
              if res.value
                s_address = document.getElementById('uid_address').value
                self.selected_address = s_address
                self.selected_shipping = res.value
              else
                self.selected_shipping = false
          else
            states = data.states.map((e) => "<option value='#{e.idstate}'>#{e.name}</option>")
            Swal.fire({
              icon: 'error',
              title: data.message,
              confirmButtonText: 'Agregar'
              cancelButtonText: 'Cancelar'
              showCancelButton: true
              allowOutsideClick: false
              html:
                "
                <div class='uk-text-left'>
                  <label for='address' class='uk-form-label uk-text-bolder'>Dirección</label>
                  <input type='text' name='address' id='address' class='uk-input swal2-input uk-margin-auto-vertical' placeholder='(Calle 00 ...)'>
                  <label for='neighborhood' class='uk-form-label uk-text-bolder'>Barrio</label>
                  <input type='text' name='neighborhood' id='neighborhood' class='uk-input swal2-input uk-margin-auto-vertical' placeholder='(barrio ...)'>
                  <div data-controller='master-detail' data-master-detail-victim='#city_id' data-master-detail-url='/states'>
                    <label for='state_id' class='uk-form-label uk-text-bolder'>Departamento</label>
                    <select id='state_id' class='swal2-input uk-input uk-select uk-margin-auto-vertical' data-target='master-detail.master' data-action='master-detail#setVictims'>
                      <option value=''>Seleccione una opción</option>
                      "+states+"
                    </select>
                  </div>
                  <label for='city_id' class='uk-form-label uk-text-bolder'>Ciudad</label>
                  <select id='city_id' class='swal2-input uk-input uk-select uk-margin-auto-vertical'>
                    <option value=''>Seleccione una opción</option>
                  </select>
                  <label for='name_address' class='uk-form-label uk-text-bolder'>Nombre para identificar esta dirección</label>
                  <input type='text' name='name_address' id='name_address' class='uk-input swal2-input uk-margin-auto-vertical' placeholder='(Mi casa, etc...)'>
                </div>
                "
              footer: "<a href='#{data.url}' class='uk-button blue-button'>Agregar otra dirección</a>"
              preConfirm: ->
                name = document.getElementById('name_address').value
                address = document.getElementById('address').value
                neighborhood = document.getElementById('neighborhood').value
                state_id = document.getElementById('state_id').value
                city_id = document.getElementById('city_id').value
                if name == '' || address == '' || neighborhood == '' || state_id == '' || city_id == ''
                  Swal.showValidationMessage('Dedes llenar los campos')
                else if name.length > 45
                  Swal.showValidationMessage('Nombre para identificar esta dirección es demasiado largo')
                else if address.length > 90
                  Swal.showValidationMessage('Dirección es demasiada larga')
                else if neighborhood.length > 45
                  Swal.showValidationMessage('Barrio es demasiado largo')
            }).then (res) ->
              if res.value
                self.selected_shipping = res.value
                name = document.getElementById('name_address').value
                address = document.getElementById('address').value
                neighborhood = document.getElementById('neighborhood').value
                state_id = document.getElementById('state_id').value
                city_id = document.getElementById('city_id').value
                address = {
                  name: name,
                  address: address,
                  neighborhood: neighborhood,
                  state_id: state_id,
                  city_id: city_id,
                  customer_id: uid
                }
                AxiosRequest('/customers/shopping_carts/add_address', {address: address}, 'post').then ({data}) ->
                  self.selected_address = data.idaddress
              else
                self.selected_shipping = false
    else if selected_pickup
      request = Swal.fire {
        title: "Servicio: #{service.title}"
        text: "Tienda: #{store.store_name}"
        confirmButtonText: 'Continuar'
        cancelButtonText: 'Cancelar'
        showCancelButton: true
        allowOutsideClick: false
      }
      request.then (res) =>
        if res.value
          self.selected_pickup = res.value
          self.price_shipping = 0
        else
          self.selected_pickup = res.value
