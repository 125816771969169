#External Libraries
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VueAutonumeric from 'vue-autonumeric'
import UniqBy from 'lodash.uniqby'
import Moment from 'moment'
import VCalendar from 'v-calendar'

# Local imports
import fetchServices from '../methods/services/fetch-services'
import AddCart from '../methods/products/add_cart'
import Delivery from '../methods/products/delivery'
import DeleteFavourite from '../methods/items/delete_favourite'
import Favourite from '../methods/items/favourite'
import toCurrency from '../filters/to_currency'
import AxiosRequest from "../utils/axios-request"

Vue.use(TurbolinksAdapter)
Vue.use VCalendar

document.addEventListener 'turbolinks:load', () ->
  el = document.getElementById 'index-service'
  return false unless el?

  dev = document.getElementById('dev').dataset.dev

  if JSON.parse dev
    router_api = 'http://dev.fenixrepuestos.com:3000'
  else
    router_api = 'http://fenixrepuestos.com'

  url_mode = (property) ->
    resp = false
    resp = el.dataset[property] if el.dataset.hasOwnProperty(property)
    resp
  data = ->
    { searchParams } = new URL(window.location.href)
    # Default values
    categories = []
    causes = []
    subcategories = []
    page = 1
    discounts = window.location.pathname == '/descuentos'
    sort =
      updated_at: 'desc'
      public_price_published_price: false
      item_title : 'asc'
    # Url params
    categories_param = parseInt(url_mode('category') || searchParams.get('category'))
    subcategories_param = parseInt(url_mode('subcategory') || searchParams.get('subcategory'))
    causes_param = parseInt(url_mode('cause') || searchParams.get('cause'))

    # Negotiation
    categories.push(categories_param) if categories_param
    causes.push(causes_param) if causes_param
    subcategories.push(subcategories_param) if subcategories_param
    page |= parseInt searchParams.get('page')
    keywords = searchParams.get('keyword')
    discounts |= parseInt searchParams.get('discounts')
    {
      favourites: []
      services: []
      filter_interactions: 1
      subcategories: []
      subcategory_names: []
      list_mode: 'grid'
      page
      selected_shipping: undefined
      selected_pickup: undefined
      selected_date: undefined
      selected_address: undefined
      store_select: undefined
      select_service: undefined
      date: undefined
      available_dates: undefined
      available_times: undefined
      holidays: undefined
      last_service: undefined
      time_select: undefined
      price_shipping: 0
      loading: true
      attributes: [
        {
          dot: 'blue'
          dates: undefined
          popover: {
            label: 'Día festivo'
          }
        }
      ]
      search_filters: {
        categories
        causes
        discounts
        keywords
        subcategories
        price_from: ''
        price_to: ''
      }
      sort
    }
  new Vue {
    el
    data
    mounted: ->
      @getSubcategories(@search_filters.categories, false)
      @getHolidays()
      @GetFavourite()
    methods: {
      toggleListMode: ->
        @list_mode = if @list_mode is 'grid' then 'list' else 'grid'
        @filtersChanged()
      fetchServices
      AddCart
      Delivery
      Favourite
      DeleteFavourite
      filtersChanged: ->
        window.scrollTo(0, 0)
        @page = 1
        @fetchServices()
      assignProperty: (property, value) ->
        @search_filters[property] = value
      clearProperty: (property, reload = true) ->
        @search_filters[property] = '';
        @fetchServices() if reload
      infiniteHandler: ($state) ->
        @fetchServices('infinite', $state)
      resetState: ->
        history.pushState({}, 'Ver Todos Nuestros Productos', document.location.origin + document.location.pathname)
        Object.assign @$data, data()
      getSubcategories: (categories, launch_control = true) ->
        @search_filters.subcategories = [] if launch_control
        subcats = []
        if categories.length
          for category in categories
            AxiosRequest("/subcategories/#{category}.json").then ({ data }) =>
              subcats.push(data...)
              @subcategories = UniqBy(subcats, 'id')
        else
          @subcategories = []
      sortControl: (order) ->
        @sort = order
        @filtersChanged()
      canAdd: (service) ->
        if @date && @time_select != null
          if @selected_shipping
            Flag = true if @selected_shipping
          else if @selected_pickup
            Flag = true if @selected_pickup
        else
          Flag = false
        not (Flag)
      availableDate: (store, service) ->
        $.get("#{router_api}/api/v1/services/#{service}/available_dates.json").done (data) =>
          find_dates = data.available_dates.find((e) => e.store.id == store)
          @available_dates = find_dates?.dates.map((e) => Moment(e).format('YYYY-M-DD'))
      availableHour: (date, store, service) ->
        $.get("#{router_api}/api/v1/services/#{service}/stores/#{store}/hours", { date: Moment(date).format('YYYY-MM-DD') }).done (data) =>
          @available_times = data.hours.map((e) => { hour: Moment.parseZone(e).format('h:mm A'), main: e})
      lastService: (store, service) ->
        index = service.stores?.findIndex((e) => e.store_id == store)
        @last_service = service.stores[index]?.night_surcharge_hour
      getHolidays: () ->
        $.get("/servicios/holidays.json").done (data) =>
          @holidays = data.holidays.map((e) => new Date(Moment(e.date).format('YYYY, M, DD')))
          @attributes[0].dates = @holidays
      isSameOrAfter: (last_service, time_select) ->
        Moment(time_select).isSameOrAfter(last_service)
      isHoliday: (date) ->
        @holidays?.some((e) => Moment(e).format('YYYY-M-DD') == Moment(date).format('YYYY-M-DD'))
      GetFavourite: ->
        query = $.get "/servicios.json"
        query.done (res) =>
          @favourites = res.favourites
      ServiceFavourite: (item, uid) ->
        unless ! uid
          @favourites?.some((e) => (e.item_id == item))
      ReloadFavourite: ->
        @GetFavourite()
    }
    watch: {
      'search_filters.categories': (categories) ->
        @filtersChanged()
        @getSubcategories(categories)
      'search_filters.subcategories': -> @filtersChanged()
      'search_filters.price_from': -> @filtersChanged()
      'search_filters.price_to': -> @filtersChanged()
      'search_filters.keywords': -> @filtersChanged()
      'search_filters.causes': -> @filtersChanged()
      'search_filters.discounts': -> @filtersChanged()
      store_select: (val) ->
        @date = undefined
        @time_select = undefined
        @lastService(val, @services.find((e) => e.id == @select_service))
        @availableDate(val, @select_service)
      date: (val) ->
        @availableHour(val, @store_select, @select_service)
    }
    components: {
      InfiniteLoading
      VueAutonumeric
    }
    computed: {
      active_price_filters: ->  @search_filters.price_from || @search_filters.price_to || @search_filters.discounts
      active_filters: ->
        f = @search_filters
        f.price_from || f.price_to || f.categories.length || f.keywords || f.causes.length || f.discounts
      any_delivery: ->
        flag = false
        if @selected_shipping == true || @selected_pickup == true
          flag = true
        flag
      any_option: ->
        flag = false
        if @selected_shipping == true || @selected_pickup == true || @date == null || @time_select == null
          flag = true
        flag
    }
    filters: {
      toCurrency
    }
  }
