import {get} from 'axios'
import {debounce} from 'throttle-debounce'
import UniqBy from 'lodash.uniqby'

export default debounce(1000, false, (mode = 'get', $state) ->
  @loading = true
  {
    categories
    discounts
    causes
    keywords
    subcategories
    title
    price_from
    price_to
  } = @search_filters
  q = {
    items_with_discount: discounts if discounts
    item_cause_id_in: causes if causes.length
    item_subcategory_id_in: subcategories if subcategories.length
    item_category_id_in: categories if categories.length
    item_title_or_item_cause_name_i_cont_all: keywords if keywords
    item_public_price_published_price_gteq: price_from if price_from
    item_public_price_published_price_lteq: price_to if price_to
    item_title: title if title
  }
  request = get('/services/search.json', {params: {id: @id, q, @page, sort: [@sort]}})
  page = 0
  if mode == 'infinite'
    request.then ({data}) =>
      @loading = false
      quantity = data.services.length
      new_services = @services
      new_services.push(data.services...)
      @services = UniqBy(new_services, 'id')
      if (quantity < 15)
        $state.complete()
        @page = 0
      else
        @page++
        $state.loaded()
  if mode == 'get'
    @services = []
    request.then ({data}) =>
      @page++
      @services = data.services
      @loading = false
)
