import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'
import Moment from 'moment'

export default (uid, product, options = null, service = null, store = null, sel_pick = false, sel_ship = false, address = null) ->
  if product
    final_data_product = {
      type: product.type_id
      title: product.title,
      image: product.image,
      limit: if options then options.unit else product.units,
      price: product.price,
      options: options,
      quantity: parseInt(@quantity),
      product_id: product.id
      address_id: address,
      store_id: store,
      pickup: sel_pick,
      shipping: sel_ship
      total_discounts: product.discounts,
      total_taxes: product.taxes,
      total: product.price * parseInt(@quantity)
    }
  else if service
    total = service.price
    if @isHoliday(@date) && @isSameOrAfter(@last_service, @time_select.main)
      total = service.price + service.night_surcharge + service.holiday_surcharge
    else if @isHoliday(@date)
      total = service.price + service.holiday_surcharge
    else if @isSameOrAfter(@last_service, @time_select.main)
      total = service.price + service.night_surcharge
    final_data_service = {
      type: service.type_id
      title: service.title,
      image: service.image,
      price: total,
      normal_price: service.price,
      service_id: service.id
      address_id: address,
      store_id: store,
      pickup: pickup,
      shipping: shipping
      date_text: Moment(@date).lang('es').format('MMMM D YYYY'),
      date: Moment(@date).format(),
      time_select: @time_select.main,
      time_text: @time_select.hour,
      holiday_surcharge: if @isHoliday(@date) then service.holiday_surcharge else 0,
      night_surcharge: if @isSameOrAfter(@last_service, @time_select.main) then service.night_surcharge else 0,
      isHoliday: @isHoliday(@date),
      isSameOrAfter: @isSameOrAfter(@last_service, @time_select.main),
      total_discounts: service.discounts,
      total_taxes: service.taxes,
      total: total
    }

  AxiosRequest('/customers/shopping_carts/add.json', { product: product.id, quantity: @quantity, service: service.id }, 'post').then ({ data }) =>
    ls = new SecureLS();
    actual_cart = ls.get('cart');
    # Incializador cart
    if !actual_cart
      actual_cart = {}
    if !actual_cart[uid]
      actual_cart[uid] = []
    # Verificamos que el producto que estamos agreagando exita en el carrito
    if product
      cart_some = actual_cart[uid].some((e) => (
        e.product_id == product.id && e.options.product_inventory_id == options.product_inventory_id && e.address_id == final_data_product.address_id && e.store_id == final_data_product.store_id
      ))

      if cart_some
        # Si exite el producto lo recorremos y actulizamos las unidades
        for item, value of actual_cart[uid]
          units = if options then options.unit else product.units
          if value.product_id == product.id
            # Validar que la cantidad no sea mayor a la limite
            if value.quantity < units
              if value.options && value.options.any_options = true
                if value.options.product_inventory_id == options.product_inventory_id && value.address_id == final_data_product.address_id && value.store_id == final_data_product.store_id
                  value.quantity += parseInt @quantity
              else
                value.quantity += parseInt @quantity
              Swal.fire({
                title: data.message,
                icon: 'success',
                showConfirmButton: false,
                inputAttributes:
                  autocapitalize: 'off'
                footer: "
                        <div class='uk-flex uk-flex-wrap uk-flex-around'>
                          <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a>
                          <a class='uk-button uk-button-secondary white-text ' href='/productos' data-turbolinks='false'>Ver más productos</a>
                        </div>
                      "
              })
            else
              Swal.fire({
                icon: 'error',
                title: "Error",
                text: "Número de unidades no es válido (Unidades disponibles: #{units})"
              })
      else
        # Sino lo agregamos al carrito
        actual_cart[uid].push(final_data_product)
        Swal.fire({
          title: data.message,
          icon: 'success',
          showConfirmButton: false,
          inputAttributes:
            autocapitalize: 'off'
          footer: "
                  <div class='uk-flex uk-flex-wrap uk-flex-around'>
                    <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a>
                    <a class='uk-button uk-button-secondary white-text' href='/productos' data-turbolinks='false'>Ver más productos</a>
                  </div>
                "
        })
    else if service
      servi_some = actual_cart[uid].some((e) => e.service_id == service.id && Moment(e.date).format('YYYY-MM-DD') == Moment(@date).format('YYYY-MM-DD') && Moment(e.time_select).format('h:mm A') == Moment(@time_select.main).format('h:mm A') && e.store_id == @store_select)
      if servi_some
        Swal.fire({
          title: 'Este service ya esta agendado',
          icon: 'error',
          showConfirmButton: false,
          inputAttributes:
            autocapitalize: 'off'
          footer: "
            <div class='uk-flex uk-flex-wrap uk-flex-around'>
              <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a>
              <a class='uk-button uk-button-secondary white-text' href='/servicios' data-turbolinks='false'>Ver más servicios</a>
            </div>
          "
        })
      else
        # Sino lo agregamos al carrito
        actual_cart[uid].push(final_data_service)
        Swal.fire({
          title: data.message,
          icon: 'success',
          showConfirmButton: false,
          inputAttributes:
            autocapitalize: 'off'
          footer: "
            <div class='uk-flex uk-flex-wrap uk-flex-around'>
              <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a>
              <a class='uk-button uk-button-secondary white-text' href='/servicios' data-turbolinks='false'>Ver más servicios</a>
            </div>
          "
        })
    ls.set('cart', actual_cart)
